<section class="pageBanner">
    <div class="container">
      
      <div class="row">
        <div class="col-9">
          <div class="pageBannerContent">        
            <h2 class="pageTitl white mt-5">DSW University</h2>
            <h3 class="white">PG Program in AI & Machine Learning</h3>
            <p class="white">Recommended as a preparatory data sciences programme for fresh graduates and professionals with
              limited or no programming background.'</p>
              <button class="btn btn-primary btn-lg">Apply Now</button>             
          </div>
        </div>
      </div>
      
    </div>
  </section>
<!-----------------Page Layout Section start ----------->
  <section class="pageLayout">
    <div class="backGroundMask">
      <div class="maskGrid">
        <div class="left"></div>
        <div class="right"></div>
        <div class="color"></div>
      </div>     
    </div>
     
   
<!-----------------TrainingEvtSec Section start -----------> 
    <section class="TrainingsSec TrainingEvtSec">

      <!-----------------About Course Section start ----------->
      <div class="AboutCourseSec b-light-blue">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <ul class="list">
                <li>
                  <p class="para">Start Date</p>
                  <h5 class="primary">October 2nd, 2021</h5>
                </li>
                <li>
                  <p class="para">Duration</p>
                  <h5 class="primary">6 Months</h5>
                </li>
                <li>
                  <p class="para">Course Format</p>
                  <h5 class="primary">Live Online</h5>
                </li>
                <li>
                  <p class="para">Fees</p>
                  <h5 class="primary">₹ 1,50,000</h5>
                </li>
                <li>
                  <p class="para">EMI Options</p>
                  <h5 class="primary">Starts at ₹ 9,999/month</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>      
      <!-----------------About Course Section start ----------->
      

      <!-----------------Icon Section start -----------> 
      <div class="why-train-at">
        <div class="container">    
          <div class="row why-train-at-list">
            <div class="col-3 count">
              <span class="iconsprt coursesType curriculum"></span>
              <p class="para text-center">20+ hours of highly interactivelive training</p>
            </div>
            <div class="col-3 count">
              <span class="iconsprt coursesType projects"></span>
              <p class="para text-center">Mentoring by industry practioners</p>
            </div>
            <div class="col-3 count">
                <span class="iconsprt coursesType expert"></span>
                <p class="para text-center">4 end to end real world data projects</p>
            </div>
            <div class="col-3 count">
                <span class="iconsprt coursesType mentorship"></span>
                 <p class="para text-center">Practical Labs & hackathons</p>
            </div>
            
            <div class="col-3 count">
              <span class="iconsprt coursesType curriculum"></span>
              <p class="para text-center">20+ hours of highly interactivelive training</p>
            </div>
            <div class="col-3 count">
              <span class="iconsprt coursesType projects"></span>
              <p class="para text-center">Mentoring by industry practioners</p>
            </div>
            <div class="col-3 count">
                <span class="iconsprt coursesType expert"></span>
                <p class="para text-center">4 end to end real world data projects</p>
            </div>
            <div class="col-3 count">
                <span class="iconsprt coursesType mentorship"></span>
                 <p class="para text-center">Practical Labs & hackathons</p>
            </div>

          </div>                

        </div>
      </div>
      <!-----------------Icon Section end -----------> 


      <!-----------------skill Section start ----------->

      <div class="AboutSkil">
        <div class="container">
          
          <div class="row">
            <div class="col-8">
              <div class="contentBox">
                <h3 class="primary mb-4">About PG Program in AI & Machine Learning</h3>
                <p>Start your journey with this preparatory-level programme that introduces you to the world of data
                  science. As part of your course structure, you will learn Data Science fundamentals like Python, R,
                  Data Preprocessing, Data Manipulation, Data Visualization and Exploratory Data Analysis which
                  will help you build a foundation for learning Machine Learning, Deep Learning and AI.</p>
                <p>Each skill can improve your ability to see patterns, discover insights, explore strategies and
                  implement data science driven programmes that could change the world.</p>
              </div>
              <div class="contentBox">
                <h3 class="primary mb-4">Built a rewarding career with the world's most in demand skillset</h3>
                <p>This foundational course offers all the integral data science skills an aspiring data professional
                  requires. Reshape your role or choose career paths you can grow into including Data Analyst,
                  Data Scientist, Machine Learning Scientist, Statistician, Python programmer, besides others.  </p>
              </div>
            </div>
            <div class="col-4">
              <div class="contentBox">
                <p><strong>Skills you will gain:</strong></p>
              <ul class="items">
                <li>Python Programming</li>
                <li>R Programming</li>
                <li>Logistic Regression</li>
                <li>Machine Learning (ML) Algorithms</li>
                <li>Artificial Intelligence (AI)</li>
                <li>Exploratory data analysis</li>
              </ul>
            </div>
            </div>
          </div>

        </div>
      </div>

      <!-----------------skill Section end ----------->


      <!-----------------Programme Section start ----------->

      <div class="AboutSkil b-light-blue pt-3 pb-3">
        <div class="container">
          
          <div class="row">
            <div class="col-6">
              <div class="contentBox">
                <h3 class="primary mb-4">Who is this Program for</h3>
                <p>Engineers, Marketing & Sales Professionals, Freshers, Domain
                  Experts, Software & IT Professionals.</p>
              </div>
            </div>
            <div class="col-6">
              <div class="contentBox">
                <h3 class="primary mb-4">Minimum Eligibiity</h3>
                <p>Bachelor’s Degree with minimum 50% or equivalent passing marks.
                  No coding experience required.</p>
              </div>
            </div>
            <div class="col-6">
              <div class="contentBox">
                <h3 class="primary mb-4">Job Opportunities</h3>
                <p>Data Analyst, Data Scientist, Data Engineer, Product Analyst,
                  Machine Learning Engineer, Decision Scientist.</p>
              </div>
            </div>
            <div class="col-6">
              <div class="contentBox">
                <h3 class="primary mb-4">Delivery Mode</h3>
                <p>The training will be conducted on weekends through online mode,
                  on Microsoft teams platform.</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-----------------Programme Section end ----------->


      <!-----------------CurseContent Section start ----------->

      <div class="courseCont">
        <div class="container">

          <div class="row">
            <div class="col-12">
              <h2 class="sec-title">Course content</h2>
            </div>
          </div>
          
          <div class="row justify-content-start mt-3">
            <div class="col-7">                          
                  <mat-accordion>
                    <mat-expansion-panel [expanded]="true">
                      <mat-expansion-panel-header>
                        <h5 class="primary">Module 1 : Introduction to Data Science</h5>
                      </mat-expansion-panel-header>
                      <ul class="list">
                        <li>What is Data Science and why it is a hot topic of 21st century ?</li>
                        <li>Who is a Data scientist ?</li>
                        <li>What skills do you need to become a Data scientist ?</li>
                      </ul>
                    </mat-expansion-panel>
       
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <h5 class="primary">Module 2 : Fundamentals of Python</h5>
                      </mat-expansion-panel-header>
                      <ul>
                        <li>What is Data Science and why it is a hot topic of 21st century ?</li>
                        <li>Who is a Data scientist ?</li>
                        <li>What skills do you need to become a Data scientist ?</li>
                      </ul>
                    </mat-expansion-panel>
      
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <h5 class="primary">Module 3 : Data Structures & Control Structures in Python</h5>
                      </mat-expansion-panel-header>
                      <p>Module 3</p>
                    </mat-expansion-panel>          
      
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h5 class="primary">Module 4 : Function and Libraries in Python</h5>
                    </mat-expansion-panel-header>
                    <p>Module 4</p>
                  </mat-expansion-panel>
      
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h5 class="primary">Module 5 : Data Manipulation & Pre-processing</h5>
                    </mat-expansion-panel-header>
                    <p>Module 5</p>
                  </mat-expansion-panel>
      
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h5 class="primary">Module 6 : Data Visualization and Introduction to EDA</h5>
                    </mat-expansion-panel-header>
                    <p>Module 6</p>
                  </mat-expansion-panel>
      
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h5 class="primary">Module 7 : Exploratory Data Analysis</h5>
                    </mat-expansion-panel-header>
                    <p>Module 7</p>
                  </mat-expansion-panel>
      
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <h5 class="primary">Module 8 : Industry Insights and Real Time Use Cases</h5>
                    </mat-expansion-panel-header>
                    <p>Module 8</p>
                  </mat-expansion-panel>
      
                </mat-accordion>
      
            </div>
            
            <div class="col-5">
              <div class="row">
                <div class="col-6">
                  <div class="count">
                    <strong>500</strong>
                    <span>Hours of Content</span>                    
                  </div>
                </div>
                <div class="col-6">
                  <div class="count">
                    <strong>4</strong>
                    <span>Case Studies</span>                    
                  </div>
                </div>
                <div class="col-6">
                  <div class="count">
                    <strong>8</strong>
                    <span>Projects</span>                    
                  </div>
                </div>
                <div class="col-6">
                  <div class="count">
                    <strong>9</strong>
                    <span>Months Program</span>                    
                  </div>
                </div>
              </div>
            </div>

          </div>
      
          <div class="row">
            <div class="col-12">
              <button class="btn btn-outline-primary btn-lg">Download course curriculum</button>
            </div>
          </div>
      
        </div>
      </div>

      <!-----------------CurseContent Section end ----------->


      <!----------------- Industry projects Section start ------------->
<div class="IndustryProjects">
  <div class="container">

    <div class="row justify-content-start">
      <div class="col-12">
        <h2 class="sec-title">Industry Projects</h2>
      </div>
    </div>

    <div class="row justify-content-start">
      <div class="col-12">

        <div class="row">
          <div class="col-4">
            <div class="t-list">
              <div class="header light-blue">
                <p class="text-danger">Project 1</p>
                <h5>Products rating prddiction Amazon</h5>
              </div> 
              <div class="content">        
                <p>
                  Help Amazon, a US-based e-commerce company, improve its recommendation engine by
                  prediction rating for the non-rated products and adding them to recommendations accordingly.
                </p>
            </div>            
          </div>
        </div>
          <div class="col-4">
            <div class="t-list">
              <div class="header light-blue">
                <p class="text-danger">Project 2</p>
                <h5>Demand forecasting for Walmart</h5>
              </div>
              <div class="content"> 
                <p>
                  Predict accurate sales for 45 walmart stores, considering the impact
                  of promotional markdown events. Check it marcroeconomic factors have an impact on sales.
                </p>
              </div>
           </div>
          </div>
          <div class="col-4">
            <div class="t-list">
              <div class="header light-blue">
                <p class="text-danger">Project 2</p>
                <h5>Demand forecasting for Walmart</h5>
              </div>
              <div class="content"> 
                <p>
                  Predict accurate sales for 45 walmart stores, considering the impact
                  of promotional markdown events. Check it marcroeconomic factors have an impact on sales.
                </p>
              </div>
           </div>
          </div>
        </div>

      </div>     
    </div>
  </div>
</div>

<!----------------- Industry projects Section end ------------->

<!----------------- programming Language Section start ------------->
<div class="programmingSec">
  <div class="container">

    <div class="row">
      <div class="col-12">
        <h2 class="sec-title mb-4">Programming Languages and Tools Covered</h2>
        <ul class="logo">
         <li>
           <a href="#"><img src="../../assets/images/aboutUs/IBM_logo.png" alt="IBM"></a>
          </li>
          <li>
            <a href="#"><img src="../../assets/images/aboutUs/amazon-aws-logo-transparent.png" alt="IBM"></a>
           </li>
           <li>
            <a href="#"><img src="../../assets/images/aboutUs/azu.png" alt="IBM"></a>
           </li>
           <li>
            <a href="#"><img src="../../assets/images/aboutUs/IBM_logo.png" alt="IBM"></a>
           </li>
           <li>
             <a href="#"><img src="../../assets/images/aboutUs/amazon-aws-logo-transparent.png" alt="IBM"></a>
            </li>
            <li>
             <a href="#"><img src="../../assets/images/aboutUs/azu.png" alt="IBM"></a>
            </li>
        </ul>
      </div>
    </div>

  </div>
</div>

<!----------------- programming Language Section end ------------->


 <!----------------- Admission prosses Section start ------------->
 <div class="IndustryProjects">
  <div class="container">

    <div class="row justify-content-start">
      <div class="col-12">
        <h2 class="sec-title">Admission Process</h2>
      </div>
    </div>

    <div class="row justify-content-start">
      <div class="col-12">

        <div class="row">
          <div class="col-4">
            <div class="t-list">
              <div class="header light-blue">
                <p class="text-danger">Project 1</p>
                <h5>Take the Online Eligibility Test</h5>
              </div> 
              <div class="content">        
                <p>
                  Complete your application to take the 17-minute online eligibility test with 11
                  questions to kick-start the admission process. The test is designed to assess
                  your quantitative & logical aptitude.
                </p>
            </div>            
          </div>
        </div>
          <div class="col-4">
            <div class="t-list">
              <div class="header light-blue">
                <p class="text-danger">Project 2</p>
                <h5>Get Shortlisted & Receive your Offer Letter</h5>
              </div>
              <div class="content"> 
                <p>
                  Our Admissions Committee will review your test score & profile. Upon
                  qualifying, an Offer Letter will be sent to you confirming your admission to the
                  PGP in AI & Machine Learning.
                </p>
              </div>
           </div>
          </div>
          <div class="col-4">
            <div class="t-list">
              <div class="header light-blue">
                <p class="text-danger">Project 2</p>
                <h5>Block your Seat & Begin the PG Program</h5>
              </div>
              <div class="content"> 
                <p>
                  Block your seat with a payment of INR 25,000 to enroll into the programme.
                  Begin with your Prep course and start your Data Science journey!
                </p>
              </div>
           </div>
          </div>
        </div>

      </div>     
    </div>
  </div>
</div>

<!----------------- Admission prosses Section end ------------->


<!-----------------Post Graduate Section start ----------->

<div class="postGraduateSec">
  <div class="container">

    <div class="row">
      <div class="col-7">
        <h2 class="sec-title mt-0">PG Program in AI & Machine Learning FAQs</h2>
        <p>Participants will be provided a Professional certificate from IBM, upon
          completion of all the four course modules.</p>
          <div class="box">
            <h5 class="primary ng-tns-c216-19">Differentiate Yourself With A Professional Certificate</h5>
            <p>The knowledge and skills you have gained working on projects,
              simulations, case studies will set you ahead of the competition.</p>
          </div>
          <div class="box">
            <h5 class="primary ng-tns-c216-19">Share Your Achievement</h5>
            <p>Talk about it on Linkedin,Twitter,Facebook - Tell your friends and
              colleagues about it.</p>
          </div>
      </div>

      <div class="col-5">
        <div class="certificate">
          <a href="#"><img src="../../assets/images/cirtificate.png" alt="IBM"></a>
        </div>
      </div>

    </div>

  </div>

</div>

<!-----------------Post Graduate Section end ----------->


<!-----------------PGProgramme Section start ----------->

<div class="courseCont">
  <div class="container">

    <div class="row">
      <div class="col-12">
        <h2 class="sec-title">PG Program in AI & Machine Learning FAQs</h2>
      </div>
    </div>
    
    <div class="row justify-content-start mt-3">
      <div class="col-12">                          
            <mat-accordion>
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <h5 class="primary">What is Python?</h5>
                </mat-expansion-panel-header>
                <ul class="list">
                  <li>Python is an object-oriented programming language with integrated dynamic semantics, used primarily for application and web development.
                    The widely used language offers dynamic binding and dynamic typing options.</li>
                </ul>
              </mat-expansion-panel>
 
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <h5 class="primary">What is Data Science?</h5>
                </mat-expansion-panel-header>
                <ul>
                  <li>What is Data Science and why it is a hot topic of 21st century ?</li>
                </ul>
              </mat-expansion-panel>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <h5 class="primary">Why should I learn Python for Data Science?</h5>
                </mat-expansion-panel-header>
                <p>Module 3</p>
              </mat-expansion-panel>          

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <h5 class="primary">Do I need coding experience to learn Python?</h5>
              </mat-expansion-panel-header>
              <p>Module 4</p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <h5 class="primary">What is the job outlook for Data Science?</h5>
              </mat-expansion-panel-header>
              <p>Module 5</p>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <h5 class="primary">Can I learn Data Science Foundation course online?</h5>
              </mat-expansion-panel-header>
              <p>Module 6</p>
            </mat-expansion-panel>

          </mat-accordion>

      </div>
      

    </div>

  </div>
</div>

<!-----------------PGProgramme Section end ----------->

    
    </section>
<!-----------------TrainingEvtSec Section end -----------> 


<!-----------------Page Layout Section end ----------->
  </section>




  <script>
    function validateEmail207144000000261136() {
      var form = document.forms['WebForm207144000000261136'];
      var emailFld = form.querySelectorAll('[ftype=email]');
      var i;
      for (i = 0; i < emailFld.length; i++) {
        var emailVal = emailFld[i].value;
        if ((emailVal.replace(/^\s+|\s+$/g, '')).length != 0) {
          var atpos = emailVal.indexOf('@');
          var dotpos = emailVal.lastIndexOf('.');
          if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
            alert('Please enter a valid email address. ');
            emailFld[i].focus();
            return false;
          }
        }
      }
      return true;
    }
  
    function checkMandatory207144000000261136() {
      var mndFileds = new Array('NAME', 'Email', 'COBJ1CF1', 'COBJ1CF2', 'COBJ1CF3', 'COBJ1CF4', 'COBJ1CF5', 'COBJ1CF6');
      var fldLangVal = new Array('First\x20Name', 'Email', 'Message\x20Box', 'Last\x20Name', 'Phone\x20Number', 'Job\x20Title', 'Company', 'Country');
      for (i = 0; i < mndFileds.length; i++) {
        var fieldObj = document.forms['WebForm207144000000261136'][mndFileds[i]];
        if (fieldObj) {
          if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length == 0) {
            if (fieldObj.type == 'file') {
              alert('Please select a file to upload.');
              fieldObj.focus();
              return false;
            }
            alert(fldLangVal[i] + ' cannot be empty.');
            fieldObj.focus();
            return false;
          } else if (fieldObj.nodeName == 'SELECT') {
            if (fieldObj.options[fieldObj.selectedIndex].value == '-None-') {
              alert(fldLangVal[i] + ' cannot be none.');
              fieldObj.focus();
              return false;
            }
          } else if (fieldObj.type == 'checkbox') {
            if (fieldObj.checked == false) {
              alert('Please accept  ' + fldLangVal[i]);
              fieldObj.focus();
              return false;
            }
          }
          try {
            if (fieldObj.name == 'Last Name') {
              name = fieldObj.value;
            }
          } catch (e) { }
        }
      }
      if (!validateEmail207144000000261136()) { return false; }
      document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
    }
  
    function tooltipShow207144000000261136(el) {
      var tooltip = el.nextElementSibling;
      var tooltipDisplay = tooltip.style.display;
      if (tooltipDisplay == 'none') {
        var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
        for (i = 0; i < allTooltip.length; i++) {
          allTooltip[i].style.display = 'none';
        }
        tooltip.style.display = 'block';
      } else {
        tooltip.style.display = 'none';
      }
    }
  </script>
  <!-- Do not remove this --- Analytics Tracking code starts -->
  <script id='wf_anal'
    src='https://crm.zohopublic.in/crm/WebFormAnalyticsServeServlet?rid=fe4ea5590f25456d620255e4b85cc30538c7477fe3e089a0429e4cdbb8620bc8gid8c5a44735491abde9b3fadbeb451658312879c5b70911e53312c7a7bfcde376agidb1956a2d91447e247afd6fcb2373c0815fb1fe4ba513801c7618ed2ca56ef1efgid4ee3a7e9ace6ab1be7c541b329164307'></script>