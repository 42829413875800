<div class="topSec sticky-top">
  
    <!--<div class="horizontal-scrolling-items">

      <div class="horizontal-scrolling-items__item" style="padding-left: 180px;">
        Register for the DSW Enterprise AI Symposium
        2024 | July 11, 4.30 to 6.30 PM | AI for Enterprises: 
        Break Barriers & Accelerate to Production Predictably, at Scale
        <a href="https://DSW-AISymposium-ai-for-enterprises.eventbrite.ie" target="_blank">
          <button class="demo-btn" mat-button style="padding: 0px;">Click here for details</button></a>
      </div>

    
    </div>-->
    <div class="topmedia">
      <div class="container">
        <div class="contactus">
          <span class="iconsprt contacticon"></span><a
            href="mailto:contactus@datasciencewizards.in">contact@datasciencewizards.ai</a>
        </div>
        <div class="socialM">
          <ul>
            <li>
              <a class="iconsprt social linkd"  target="_blank" href="https://www.linkedin.com/company/data-science-wizards">

              </a>
            </li>
            <li>
              <a class="iconsprt social inst" target="_blank" href="https://www.instagram.com/datasciencewizards/?hl=en">
              </a>
            </li>

            <li>
              <a class="iconsprt social face"  target="_blank" href="https://www.facebook.com/datasciencewizards/">

              </a>
            </li>
            <li>
              <a class="iconsprt social twit"  target="_blank" href=" https://twitter.com/dswizards">

              </a>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="menuSec">

        <nav class="navbar navbar-expand-md navbar-dark bg-dark">
          <div class="logo">
            <a class="nav-link" routerLink="/home"><img src="../assets/images/jwsLogo.png" alt="Logo" title="DSW"></a>
          </div>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="webview">
            <!-- <li> <a class="nav-link" routerLink="/solutions/insureAi" routerLinkActive="active">Platform</a></li> -->

            <li class="menuMore"> <a href="#" onclick="return false;" class="nav-link"  routerLinkActive="active">Platform</a>
              <ul class="submenu">
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" routerLink="/platform/unifyai">UnifyAI</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" routerLink="/production">Production Pilot Program</a>
                </li>
              </ul>
            </li> 

            <li class="menuMore"> <a href="#" onclick="return false;" class="nav-link"  routerLinkActive="active">Use Cases</a>
              <ul class="submenu">
                <h5 class="sub-menu-title">Use Cases by Industry</h5>
                <ng-container *ngFor="let item of headerData['data']">
                  <li class="nav-item">
                    <a class="nav-link"  [routerLink]="['/solutions']" [queryParams]="{tab: item.name}" routerLinkActive="active" routerLink="#">{{item.name}}</a>
                  </li>
                </ng-container>
              </ul>
            </li> 

            <li class="menuMore"> <a href="/" onclick="return false;" class="nav-link"  routerLinkActive="active">Resources</a>
            <ul class="submenu">
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" routerLink="/blog">Blog</a>
            </li>
            </ul>
            </li>
            <li class="menuMore"> <a href="/" onclick="return false;" class="nav-link"  routerLinkActive="active">Company</a>
              <ul class="submenu">
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" routerLink="/aboutus">About Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" routerLink="/contactUs">Contact Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" routerLink="/career">Careers</a>
                </li>
              </ul>
            </li> 

            <li class="request nav-item" routerLink="/contactUs"> <button type="button" class="btn btn-outline-primary">
              Request a Demo
            </button></li>
          </ul>

          <ul class="modeView">
            <li class="menuMore"> <a href="#"  onclick="return false;" class="nav-link"  routerLinkActive="active">Platform <em></em></a>
              <ul class="submenu">
                <li class="nav-item">
                  <a class="nav-link" data-toggle="collapse" data-target="#navbarCollapse"  routerLinkActive="active" routerLink="/platform/unifyai">UnifyAI</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link"  data-toggle="collapse" data-target="#navbarCollapse"  routerLinkActive="active" routerLink="/production">Production Pilot Program</a>
                </li>
              </ul>
            </li>
            <li class="menuMore"> <a href="#"  onclick="return false;" class="nav-link"  routerLinkActive="active">Use Cases <em></em></a>
              <ul class="submenu">
                <h5 class="sub-menu-title">Use Cases by Industry</h5>               
                <ng-container *ngFor="let item of headerData['data']">
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="collapse" data-target="#navbarCollapse"  [routerLink]="['/solutions']" [queryParams]="{tab: item.name}" routerLinkActive="active" routerLink="#">{{item.name}}</a>
                  </li>
                </ng-container>
              </ul>
            </li>
            <li class="menuMore"> <a href="#" onclick="return false;" class="nav-link"  routerLinkActive="active">Resources <em></em></a>
            <ul class="submenu">   
            <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" data-target="#navbarCollapse"  routerLinkActive="active" routerLink="/blog">Blog</a>
            </li>
            </ul>
          </li>
            <li class="menuMore"> <a href="#" onclick="return false;" class="nav-link"  routerLinkActive="active">Company<em></em></a>
              <ul class="submenu">          
                <li class="nav-item">
                  <a class="nav-link" data-toggle="collapse" data-target="#navbarCollapse"  routerLinkActive="active" routerLink="/aboutus">About Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="collapse" data-target="#navbarCollapse"  routerLinkActive="active" routerLink="/contactUs">Contact Us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="collapse" data-target="#navbarCollapse"  routerLinkActive="active" routerLink="/career">Careers</a>
                </li>
              </ul>
            </li>
            <!-- <li class="nav-item"> <a data-toggle="collapse" data-target="#navbarCollapse"  class="nav-link" routerLink="/training" routerLinkActive="active"> Training</a></li> -->
            <!-- <li class="nav-item"> <a data-toggle="collapse" data-target="#navbarCollapse"  class="nav-link" data-toggle="collapse" data-target="#navbarCollapse" routerLink="/aboutus" routerLinkActive="active">About Us</a></li> -->
            <li class="request nav-item" routerLink="/contactUs" data-toggle="collapse" data-target="#navbarCollapse"> <button type="button" class="btn btn-outline-primary">
              Request a Demo
            </button></li>

          </ul>
        </div>
        </nav>
        <!-- <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <a class="navbar-brand" href="#">Fixed navbar</a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item active">
                <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">Link</a>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled" href="#">Disabled</a>
              </li>
            </ul>
            <form class="form-inline mt-2 mt-md-0">
              <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
              <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
            </form>
          </div>
        </nav> -->
      </div>

