import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetHomePageDetailsAction } from 'src/app/store/home/home.action';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConfigurationService {
  constructor(private store:Store) {}

  initialize() {
    return new Promise<void>(resolve => {
      // this.store.dispatch(new GetHomePageDetailsAction())
     // console.log("Hii from ApplicationConfigurationService")
      resolve();
    });
  }
}
