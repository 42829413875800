
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { SecondSection, ThirdSection, WelcomeData ,PoweringAIInnovation, AITechnology } from './home.model';
import * as HomeDefaultData from './home.default.json';
import * as HomeDefaultDataProd from './home.prod.default.json';
import { GetHomePageDetailsAction, GetHomePageDetailsDefaultAction, SetHomePageDataAction } from './home.action';
import { mergeMap, tap } from 'rxjs/operators';
import { HomeService } from 'src/app/core/services/home/home.service';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';

export interface HomeSectionModel {
    second_section:         SecondSection;
    third_section:          ThirdSection;
    powering_ai_innovation: PoweringAIInnovation;
    video_links:            string;
    company_logos:          AITechnology[];
    ai_technologies:        AITechnology[];
}

@State<HomeSectionModel>({
  name: 'homeSection',
  defaults: {
    second_section:{
        heading:     '',
        sub_heading: '',
        slider:[]
    },
    third_section:{
        heading:'',
        sub_heading:'',
        cards:[]
    },
    powering_ai_innovation:{
        solution_domain:[]
    },
    video_links:"",
    company_logos: [],
    ai_technologies:[]
    // homeSectionData:
    // {
    //     ...HomeDefaultData
    // }
  }
})
@Injectable()
export class HomeState  implements NgxsOnInit{
  constructor(private store: Store, private homeService:HomeService) {}
    
    ngxsOnInit(ctx: StateContext<any>): void {
        //console.log('State initialized, now getting animals');
        // ctx.dispatch(new GetHomePageDetailsDefaultAction());
        ctx.dispatch(new GetHomePageDetailsAction());
        ctx.setState({
            homeSectionData:{...HomeDefaultData}
        });
    }           

    @Selector()
    static currentHomeSectionData(state: HomeSectionModel): HomeSectionModel {
        return state;
    }

    @Selector()
    static homeSection(state: WelcomeData): WelcomeData {
        return state;
    }

    @Selector()
    static secondSection(state: SecondSection): SecondSection {
        return state;
    }

    @Selector()
    static thirdSection(state: ThirdSection): ThirdSection {
        return state;
    }

    @Action(GetHomePageDetailsDefaultAction)
    getHomePageDefault(ctx: StateContext<HomeSectionModel>, action: HomeSectionModel) {
        const state = ctx.getState();
        ctx.setState({
        ...state,
        ...HomeDefaultData
        });
    }

    @Action(GetHomePageDetailsAction)
    // getHomePageDetails(ctx: StateContext<HomeSectionModel>, action: GetHomePageDetailsAction) {
    //     return this.homeService.getHomeData().pipe(
    //     tap(result => {
    //         if(result.status_code == 200) {
    //             const state = ctx.getState();
    //             ctx.setState({
    //                 homeSectionData: {
    //                     ...result.ai_technologies,
    //                     ...res
    //                 }
    //             });
                
    //         } else{
    //             const state = ctx.getState();
    //             if(environment.production == true) {
    //                 ctx.setState({
    //                     homeSectionData: {
    //                         ...HomeDefaultDataProd
    //                     }
    //                 });
    //             } else {
    //                 ctx.setState({
    //                     homeSectionData: {
    //                         ...HomeDefaultData
    //                     }
    //                 });
    //             }
                
    //         }
    //     }),
    //     //   mergeMap(() => ctx.dispatch(new TakeAnimalsOutside()))
    //     );
    // }

    @Action(SetHomePageDataAction)
    setHomePageData(ctx: StateContext<HomeSectionModel>, action: SetHomePageDataAction) {
        ctx.setState({
            ai_technologies:[...action.payload.ai_technologies],
            company_logos:[...action.payload.company_logos],
            powering_ai_innovation:{
                solution_domain:[...action.payload.powering_ai_innovation.solution_domain]
            },
            second_section:{...action.payload.second_section},
            third_section:{...action.payload.third_section},
            video_links:action.payload.video_links
        })
    }
}