import { Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HomeSectionModel, HomeState } from 'src/app/store/home/home.state';
import { Observable, Subscription } from 'rxjs';
import { GetHomePageDetailsAction, SetHomePageDataAction } from 'src/app/store/home/home.action';
import { PoweringAIInnovation, WelcomeData } from 'src/app/store/home/home.model';
import * as HomeDefaultData from "./store/home/home.default.json"
import * as HomeDefaultDataProd from "./store/home/home.prod.default.json"
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HomeService } from './core/services/home/home.service';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
declare let gtag: Function;
import { SetCountryCodeDataAction } from 'src/app/store/country_code/country_code.action';
import { CountryCodeState,CountryCodeModel } from 'src/app/store/country_code/country_code.state';
import * as CountryCodeDefaultData from "./store/country_code/country_code.default.json"
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { FrameBusterService  } from './core/pipe/frame-buster.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Select(HomeState.homeSection)
  homeSectionData!: Observable<HomeSectionModel>;
  stateSubscription!: Subscription;
  homeSection!: WelcomeData;
  poweringAIInnovationData:PoweringAIInnovation;

  closeResult: string;
  @ViewChild('content') content;
 
  constructor(private store:Store,private homeService:HomeService,
    private router: Router,private gtmService: GoogleTagManagerService,
    private modalService: NgbModal,private frameBusterService: FrameBusterService){

    this.router.events.subscribe(event => {
      // if (event instanceof NavigationEnd) {
      //   gtag('config', 'GTM-KN7BJW6', {
      //     'page_path': event.urlAfterRedirects
      //   });
      // }
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: 'home'
      };

      this.gtmService.pushTag(gtmTag);
        // this.gtmService.pushTag({
        //   event: 'page_view',
        //   page_path: event.urlAfterRedirects
        // });
      }
    });
  }
 
  onDeactivate() {
    document.body.scrollTop = 0;
    // Alternatively, you can scroll to top by using this other call:
    // window.scrollTo(0, 0)
  }

  ngOnInit(): void {
    //this.getCountryCodeData()
    this.frameBusterService.bustFrame();
    this.getHomeData()
  }

  // getCountryCodeData(){
  //   this.homeService.getCountryCode().subscribe(result => {
  //       let payload = {
  //           ...result
  //       }
  //       this.store.dispatch(new SetCountryCodeDataAction(payload))
  //       if (this.router.url === '/') {
  //         // Redirect to a specific route if it's '/'
  //         this.router.navigate([payload.country_code.toLowerCase( )+'/home']);
  //       }
        
  //   }),
  //   err => {
  
  //     this.store.dispatch(new SetCountryCodeDataAction({...CountryCodeDefaultData}))
  //     //this.router.navigate([CountryCodeDefaultData.country_code.toLowerCase( )+'/home']);
  //   }

  // }

  getHomeData(){
    this.homeService.getHomeData().subscribe(result => {
          if(result.status_code == 200) {
            this.homeSection = {...result}
            let payload = {
                ...result
            }
            this.store.dispatch(new SetHomePageDataAction(payload))
          } else{
              if(environment.production == true) {
                this.homeSection = {
                  ...HomeDefaultDataProd
                }
                this.store.dispatch(new SetHomePageDataAction({...HomeDefaultDataProd}))
              } else {
                this.homeSection = {
                  ...HomeDefaultData
                }
                this.store.dispatch(new SetHomePageDataAction({...HomeDefaultData}))
              }
          }
        }),
        err => {
          if(environment.production == true) {
            this.homeSection = {
              ...HomeDefaultDataProd
            }
            this.store.dispatch(new SetHomePageDataAction({...HomeDefaultDataProd}))
          } else {
            this.homeSection = {
              ...HomeDefaultData
            }
            this.store.dispatch(new SetHomePageDataAction({...HomeDefaultData}))
          }
        }
  }

 
}

