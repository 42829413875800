import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { environment } from '../../../../environments/environment.prod';
import { WelcomeData, SecondSection, ThirdSection, PoweringAIInnovation, AITechnology } from '../../../store/home/home.model';
import { EnterpriseType, UnifyAIAccelerateInnovation, WhyUnifyAI } from 'src/app/store/unifyAi-home/unifyai-home.model';
import { BlogsData } from 'src/app/store/blog/blog.model';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private readonly baseUri: string = environment.baseUri;
  private header: HttpHeaders;
  errorMsg: string = '';


  constructor(private _http: HttpClient) {
    this.header = new HttpHeaders();
    this.header.append('Content-Type', 'application/json');
    this.header.append('Access-Control-Allow-Origin' , '*');
    this.header.append('Access-Control-Allow-Headers', '*');
  }

  getBlogData(): Observable<BlogsData>{
    return this._http.get<BlogsData>(this.baseUri + 'blogs/list', { headers: this.header }).pipe(
      map((response) => {
          return response;
      }), catchError(err => {
        let errorMsg: string;
        if (err.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${err.error.message}`;
        } else {
            this.errorMsg = this.getServerErrorMessage(err);
        }
        return throwError(this.errorMsg);
      })
    );
  }

  likesBlog(id:number): Observable<BlogsData>{
    return this._http.put<BlogsData>(this.baseUri + 'blogs/likes/'+id, { headers: this.header }).pipe(
      map((response) => { 
          return response;
      }), catchError(err => {
        let errorMsg: string;
        if (err.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${err.error.message}`;
        } else {
            this.errorMsg = this.getServerErrorMessage(err);
        }
        return throwError(this.errorMsg);
      })
    );
  }

  viewsBlog(id:number): Observable<BlogsData>{
    return this._http.put<BlogsData>(this.baseUri + 'blogs/views/'+id, { headers: this.header }).pipe(
      map((response) => { 
          return response;
      }), catchError(err => {
        let errorMsg: string;
        if (err.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${err.error.message}`;
        } else {
            this.errorMsg = this.getServerErrorMessage(err);
        }
        return throwError(this.errorMsg);
      })
    );
  }

  searchBlog(category_id:number): Observable<BlogsData>{
    return this._http.post<BlogsData>(this.baseUri + 'blogs/search'+category_id, { headers: this.header }).pipe(
      map((response) => { 
          return response;
      }), catchError(err => {
        let errorMsg: string;
        if (err.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${err.error.message}`;
        } else {
            this.errorMsg = this.getServerErrorMessage(err);
        }
        return throwError(this.errorMsg);
      })
    );
  }

  sendMail(model: any): Observable<any> {
    //console.log(model);
    return this._http.post(this.baseUri + 'sendemail', model, { headers: this.header }).pipe(
      map((response) => {
          return response;
      }), catchError(err => {
        let errorMsg: string;
        if (err.error instanceof ErrorEvent) {
            this.errorMsg = `Error: ${err.error.message}`;
        } else {
            this.errorMsg = this.getServerErrorMessage(err);
        }
        return throwError(this.errorMsg);
      })
    ); 
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
        case 404: {
            return `Not Found: ${error.message}`;
        }
        case 403: {
            return `Access Denied: ${error.message}`;
        }
        case 500: {
            return `Internal Server Error: ${error.message}`;
        }
        default: {
            return `Unknown Server Error: ${error.message}`;
        }
    }
  }

}



  
