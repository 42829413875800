import { Component, OnInit, Input } from '@angular/core';
import { PoweringAIInnovation, SolutionDomain } from 'src/app/store/home/home.model';
import { environment } from '../../../environments/environment';
import * as HomeDefaultData from '../../store/home/home.default.json';
import * as HomeDefaultDataProd from '../../store/home/home.prod.default.json';
import { Router } from '@angular/router';
import { HomeSectionModel, HomeState } from 'src/app/store/home/home.state';
import { Select, Store } from '@ngxs/store';
import { CountryCodeState,CountryCodeModel } from 'src/app/store/country_code/country_code.state';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { HomeService } from 'src/app/core/services/home/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() headerData:PoweringAIInnovation;
  country_code :any

  @Select(CountryCodeState.currentCountryCode)CountryCodeData$!: Observable<CountryCodeModel>;
  countrycodeSubscription!: Subscription;
  CountrycodeSection:CountryCodeModel

  constructor(private store:Store,private router:Router,private homeService:HomeService) { }
  homeSectionData: HomeSectionModel;
  ngOnInit(): void {
    /*this.homeSectionData = this.store.selectSnapshot(HomeState.currentHomeSectionData);

    if(this.headerData == undefined) {
      if (this.homeSectionData) {
        this.headerData = this.homeSectionData.powering_ai_innovation
      }else{
        let data = environment.production == true ? HomeDefaultDataProd.powering_ai_innovation:HomeDefaultData.powering_ai_innovation
        this.headerData = {
          ...data
        }
      }
    }*/

    this.homeService.getSolutionDomian().subscribe(result => {
          
      console.log(result)
      this.headerData = result;
    }),
    err => {

    }

  }

  navigateToSolutions(currentItem:SolutionDomain){
    this.router.navigateByUrl('solutions?tab='+currentItem.name)
  }

}
