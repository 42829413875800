
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { BlogList, BlogsData,Category } from './blog.model';
import { BlogService } from 'src/app/core/services/blog/blog.service';
import { GetBlogPageAction } from './blog.action';

export interface BlogSectionModel {
    blogData:{
        list: BlogList[];
        recommendedBlogs: BlogList[];
        latestBlogs: BlogList[];
        categories: Category[];
    }
}

@State<BlogSectionModel>({
  name: 'blog',
  defaults: {
    blogData:{
        list:[],
        recommendedBlogs:[],
        latestBlogs:[],
        categories:[]
    }
  }
})
@Injectable()
export class BlogPageState implements NgxsOnInit{
  constructor(private store: Store, private blogService:BlogService) {}
    ngxsOnInit(ctx: StateContext<any>): void {
        throw new Error('Method not implemented.');
    }

    // ngxsOnInit(ctx: StateContext<any>): void {
    //     ctx.dispatch(new GetBlogPageAction());
    //     ctx.setState({
    //         blogData:{ 
    //             list:[]
    //         }
    //     });
    // }  

  @Selector()
    static blogSection(state: BlogsData): BlogsData {
        return state;
    }

    @Action(GetBlogPageAction)
    getHomePageDetails(ctx: StateContext<BlogSectionModel>, action: GetBlogPageAction) {
        return this.blogService.getBlogData().pipe(
        tap(result => {
            if(result) {
                const state = ctx.getState();
                let recommendedBlogs = result.list.filter( e => e.is_recommanded == 1);
                let latestBlogs = result.list.filter( e => e.is_latest ==1);
                let categories = result.categories;
                //console.log("recommendedBlogs",recommendedBlogs);
               // console.log("latestBlogs",latestBlogs);
                ctx.patchState({
                    blogData: {
                        list: [...result.list],
                        recommendedBlogs:[...recommendedBlogs],
                        latestBlogs:[...latestBlogs],
                        categories:[...categories],
                    }
                });
            } else{
            }
        }),
        );
    }
}