

import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
//import { CountryCodeModel } from './country_code.model';
import * as CountryCodeDefaultData from './country_code.default.json';
import { SetCountryCodeDataAction } from './country_code.action';
import { mergeMap, tap } from 'rxjs/operators';
import { HomeService } from 'src/app/core/services/home/home.service';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';

export interface CountryCodeModel{
  ip: string
  network: string
  version: string
  city: string
  region: string
  region_code: string
  country: string
  country_name: string
  country_code: string
  country_code_iso3: string
  country_capital: string
  country_tld: string
  continent_code: string
  in_eu: boolean
  postal: string
  latitude: number
  longitude: number
  timezone: string
  utc_offset: string
  country_calling_code: string
  currency: string
  currency_name: string
  languages: string
  country_area: number
  country_population: number
  asn: string
  org: string
}

@State<CountryCodeModel>({
  name: 'CountryCodeDefault',
  defaults: {
    ip: "",
    network:  "",
    version:  "",
    city:  "",
    region:  "",
    region_code:  "",
    country:  "",
    country_name:  "",
    country_code:  "",
    country_code_iso3:  "",
    country_capital:  "",
    country_tld:  "",
    continent_code:  "",
    in_eu: true,
    postal:  "",
    latitude: 0.1,
    longitude: 1,
    timezone:  "",
    utc_offset:  "",
    country_calling_code:  "",
    currency:  "",
    currency_name:  "",
    languages:  "",
    country_area: 1,
    country_population: 1,
    asn:  "",
    org:  "",
  }
})
@Injectable()
export class CountryCodeState  implements NgxsOnInit{

  constructor(private store: Store, private homeService:HomeService) {}
    
  ngxsOnInit(ctx: StateContext<any>): void {
   
  }

  @Selector()
    static currentCountryCode(state: CountryCodeModel): CountryCodeModel {
        return state;
    }

  @Action(SetCountryCodeDataAction)
    getHomePageDefault(ctx: StateContext<CountryCodeModel>, action: SetCountryCodeDataAction) {
        const state = ctx.getState();
        ctx.setState({
        ...state,
        ...action.payload
        });
    }
   
}
