import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FrameBusterService {

  constructor() { }

  bustFrame() {
    if (window !== window.top && window.top !== null) {
      window.top.location.href = window.location.href;
    }
  }
}
