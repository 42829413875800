<section class="pageBanner">
    <div class="container">
        <h2 class="pageTitl">Thank You!</h2>
    </div>
</section>
<section class="pageLayout">
    <div class="backGroundMask">
        <div class="maskGrid">
            <div class="left"></div>
            <div class="right"></div>
        </div>
    </div>
    <section class="contactUsContSec">
        <div class="container">

            <div class="thankyou">
                <img src="../../../assets/images/thank-you/thank-you.gif">
                <p><strong>Thank you for submitting your details!</strong></p>
                <p>DSW team will get back to you within 24 hours.</p>
            </div>
        </div>
    </section>
</section>