import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatModule } from '../app/mat-module/mat-module.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { PrivacypoliciesComponent } from './policies/privacypolicies/privacypolicies.component';
import { TermsandconditionsComponent } from './policies/termsandconditions/termsandconditions.component';
import { ThankYouComponent } from './thankYou/thank-you/thank-you.component';
import { CareerService } from './core/services/career.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TrainingEventComponent } from './training/training-event/training-event.component';
import { TestComponent } from './training/test/test.component';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './solution/tabs/tabs.component';
import { WhichEnterpriseSectionComponent } from './common/unifyai-home/which-enterprise-section/which-enterprise-section.component';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsModule } from '@ngxs/store';
import { HomeState } from './store/home/home.state';
import { UnifyAIHomeState } from './store/unifyAi-home/unifyai-home.state';
import { CountryCodeState } from './store/country_code/country_code.state';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { APP_INITIALIZER } from '@angular/core';
import { ApplicationConfigurationService } from './core/services/application-configuration.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlogPageState } from './store/blog/blog.state';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RecaptchaV3Module } from 'ng-recaptcha';

import { environment } from 'src/environments/environment';

const homeStates: any[] = [HomeState, UnifyAIHomeState, BlogPageState, CountryCodeState];

const initializeApp = (_config: ApplicationConfigurationService) => {
  return () => _config.initialize();
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PrivacypoliciesComponent,
    TermsandconditionsComponent,
    ThankYouComponent,
    TrainingEventComponent,
    TestComponent,
    TabsComponent
    
  ],
  imports: [
    MatModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    NgxsModule.forRoot([...homeStates]),
    NgxsStoragePluginModule.forRoot({
      key: [...homeStates],
    }),
    NgxsResetPluginModule.forRoot(),
    NgxIntlTelInputModule,
    NoopAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-KN7BJW6'
    })
  ],
  //exports: [TextReplacePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ApplicationConfigurationService],
      multi: true,
      
    },
    {provide: 'googleTagManagerId',  useValue: 'GTM-KN7BJW6'},
    ReCaptchaV3Service,
      { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.siteKey },
    CareerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
