export class GetUnifyAIHomePageDetailsAction {
    static readonly type = '[UnifyAI HomePage Details] Get UnifyAI Home Page Section Details';
    constructor() {
    }
}

export class GetUnifyAIHomePageDetailsDefaultAction {
    static readonly type = '[UnifyAI HomePage Details] Get UnifyAI Home Page Section Details';
    constructor() {
    }
}