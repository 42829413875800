import { WelcomeData } from "./home.model";
import { HomeSectionModel } from 'src/app/store/home/home.state';

export class GetHomePageDetailsAction {
    static readonly type = '[HomePage Details] Get Home Page Section Details';
    constructor() {
    }
}

export class GetHomePageDetailsDefaultAction {
    static readonly type = '[HomePage Details] Get Home Page Section Details';
    constructor() {
    }
}


export class SetHomePageDataAction {
    static readonly type = '[HomePage Data Set] Set Home Page Section Data';
    constructor(public payload: HomeSectionModel) {
    }
}