<footer>
  <!-- <div class="maskGrid">
    <div class="right">
      <div class="line"></div>
    </div>
  </div> -->

  <div class="footerPart">
    <div class="footerlinks">      
      <div class="container">
        <div class="row">
          <div class="col-12"> 
            <div class="row">
              <div class="col-12 col-lg-5 border-R qucklink mb-5 mb-lg-0">
                <img class="width-60" src="/assets/images/unifyai_white_orange_logo.png" alt="UnifyAI"><br>
                <h3 class="mt-3 mb-3 text-36 text-white"> A Data Science Wizards Platform</h3>
                <div class="row">
                  <p class="text-white col-lg-12 text-16">UnifyAI revolutionizes traditional approach by providing businesses with the ability to harness the power of AI and take their AI-driven use cases from experimentation to production. This leads to increased scalability and predictability, allowing you to stay ahead of the curve and achieve greater success.</p>
                </div>   
                <h5 class="mt-3 mb-3 text-36 text-white"> Ireland | USA | India</h5>
                
                <div class="col-lg-12 mt-5 socials footer-social-pc">
                  <div class="row">
                    <ul>
                      <li><a target="_blank" href="https://www.linkedin.com/company/data-science-wizards"><img class="width-40" src="/assets/images/linkedln.svg" alt="linkedln"></a></li>
                      <li><a target="_blank" href="https://www.instagram.com/datasciencewizards/?hl=en"><img class="width-40" src="/assets/images/instagram.svg" alt="instagram"></a></li>
                      <li><a target="_blank" href="https://www.facebook.com/datasciencewizards/"><img class="width-40" src="/assets/images/facebook.svg" alt="facebook"></a></li>
                      <li><a target="_blank" href=" https://twitter.com/dswizards"><img class="width-40" src="/assets/images/twitter.svg" alt="twitter"></a></li>
                    </ul>
                  </div>
                </div>
                
                <div class="col-lg-12 mt-3 mt-lg-5 ">
                  <div class="row email-contact">
                    <img src="/assets/images/mail.svg" alt="email"> <span><a href="mailto:Contact@datasciencewizards.ai">contact@datasciencewizards.ai</a></span>
                  </div>
                </div>
              </div>

              <div class="col-lg-1 "></div>

              <div class="col-12 col-lg-3 qucklink">
                <strong>Platform</strong>
                <ul>
                  <li> <a routerLink="/platform/unifyai">UnifyAI</a></li>
                </ul>
                <ul>
                  <li> <a routerLink="/production">Production Pilot Program</a></li>
                </ul>

                <label class="pt-5 m-0 pt-sm-3 qucklink"><strong>Use Cases</strong></label>
                <ul>
                  <li *ngFor="let item of headerData['data']">
                    <a  style="cursor: pointer;" [routerLink]="['/solutions']" [queryParams]="{tab: item.name}">{{item.name}}</a>
                  </li>
                </ul>           
                
                <div class="certificate-logo row pt-lg-5 mt-lg-5 pt-sm-0 mt-sm-0">
                  <ul class="certify">
                    
                    <li><a><img class="width-40" src="/assets/images/SOC-II.png" alt="SOC-II"></a></li>
                    <li><a><img class="width-40" src="/assets/images/ISO-27001.png" alt="ISO-27001"></a></li>
                  </ul>
                </div>
              </div>              
             
              <div class="col-12 col-lg-3 qucklink"><strong>Company</strong>
                <ul>
                  <li> <a routerLink="/aboutus">About us</a></li>
                  <li> <a routerLink="/career">Careers</a></li>
                  <li> <a routerLink="/blog">Blog</a></li>
                  <li> <a routerLink="/contactUs">Contact us</a></li>
                  <li> <a routerLink="/privacypolicies">Privacy Policies</a></li>
                  <li> <a routerLink="/termsandconditions">Terms and conditions</a></li>
                </ul>
                
              </div>

              <div class="mobile-certificate-logo col-12pt-5 mt-5 pt-sm-0 mt-sm-0" style="display: none;">
                <ul class="certify">
                  
                  <li><a><img class="mobile-certificate-logo-size" src="/assets/images/SOC-II.png" alt="SOC-II"></a></li>
                  <li><a><img class="mobile-certificate-logo-size" src="/assets/images/ISO-27001.png" alt="ISO-27001"></a></li>
                </ul>
              </div>
             
              <div class="col-lg-12 mt-3 socials footer-social-mb">
                <div class="row">
                  <ul>
                    <li><a href="#"><img class="width-40" src="/assets/images/linkedln.svg" alt="linkedln"></a></li>
                    <li><a href="#"><img class="width-40" src="/assets/images/instagram.svg" alt="instagram"></a></li>
                    <li><a href="#"><img class="width-40" src="/assets/images/facebook.svg" alt="facebook"></a></li>
                    <li><a href="#"><img class="width-40" src="/assets/images/twitter.svg" alt="twitter"></a></li>
                  </ul>
                </div>
              </div>
                
            </div>
          </div>
        </div>
        
        <div class="copyright text-center">
          <div class="cpy">Copyright © 2024 <span>Data Science Wizards </span>
        </div>
        
      </div>
      </div>
    </div>
  </div>
</footer>