export class GetBlogPageAction {
    static readonly type = '[UnifyAI Blog Page] Get UnifyAI Blog Page Section';
    constructor() {
    }
}

export class GetBlogPageDetailsAction {
    static readonly type = '[UnifyAI Blog Details Page] Get Blog Details Page';
    constructor() {
    }
}