
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import * as UnifyAiHomeDefaultData from './unifyai-home.default.json';
import * as UnifyAiHomeDefaultDataProd from './unifyai-home.prod.default.json';

import { GetUnifyAIHomePageDetailsAction, GetUnifyAIHomePageDetailsDefaultAction } from './unifyai-home.action';
import { tap } from 'rxjs/operators';
import { HomeService } from 'src/app/core/services/home/home.service';
import { Store } from '@ngxs/store';
import { UnifyAIWelcome } from './unifyai-home.model';
import { environment } from 'src/environments/environment';

export interface UnifyAIHomeSectionModel {
    unifyAISectionData: UnifyAIWelcome;
}

@State<UnifyAIHomeSectionModel>({
  name: 'unifyAI',
  defaults: {
    unifyAISectionData: 
    {
        ...UnifyAiHomeDefaultData
    }
  }
})
@Injectable()
export class UnifyAIHomeState implements NgxsOnInit{
  constructor(private store: Store, private homeService:HomeService) {}

    ngxsOnInit(ctx: StateContext<any>): void {
       // console.log('State initialized, now getting unifyAi data');
        ctx.dispatch(new GetUnifyAIHomePageDetailsDefaultAction());
        ctx.setState({
            unifyAISectionData:{...UnifyAiHomeDefaultData}
        });
    }  

  @Selector()
    static homeSection(state: UnifyAIWelcome): UnifyAIWelcome {
        return state;
    }

    @Action(GetUnifyAIHomePageDetailsDefaultAction)
    getHomePageDefault(ctx: StateContext<UnifyAIHomeSectionModel>, action: UnifyAIHomeSectionModel) {
        const state = ctx.getState();
        ctx.setState({
        ...state,
        ...UnifyAiHomeDefaultData
        });
    }

    @Action(GetUnifyAIHomePageDetailsAction)
    getHomePageDetails(ctx: StateContext<UnifyAIHomeSectionModel>, action: GetUnifyAIHomePageDetailsAction) {
        return this.homeService.getUnifyAIHomeData().pipe(
        tap(result => {
            if(result.status_code == 200) {
                const state = ctx.getState();
                ctx.patchState({
                    unifyAISectionData: {
                        ...result
                    }
                });
                
            } else{
                const state = ctx.getState();
                if(environment.production == true) {
                    ctx.patchState({
                        unifyAISectionData: {
                            ...UnifyAiHomeDefaultDataProd
                        }
                    });
                } else {
                    ctx.patchState({
                        unifyAISectionData: {
                            ...UnifyAiHomeDefaultData
                        }
                    });
                }
                
            }
        }),
        );
    }
}