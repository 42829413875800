import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HomeSectionModel, HomeState } from 'src/app/store/home/home.state';
import { Observable, Subscription } from 'rxjs';
import { GetHomePageDetailsAction } from 'src/app/store/home/home.action';
import { PoweringAIInnovation, SolutionDomain, WelcomeData } from 'src/app/store/home/home.model';
import * as HomeDefaultData from '../../store/home/home.default.json';
import * as HomeDefaultDataProd from '../../store/home/home.prod.default.json';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CountryCodeState,CountryCodeModel } from 'src/app/store/country_code/country_code.state';
import { take } from 'rxjs/operators';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HomeService } from 'src/app/core/services/home/home.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
styles: [`
  
  .scrolling-text {
    position: absolute;
    white-space: nowrap;
  }

  /* Below doesn't work to pause */

  .scrolling-text:hover, .container:hover {
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
`],
animations: [
  trigger('scroll', [
    state('on', style({right: '-900px'})),
    transition('* => *', [
      style({right: '-900px'}),
      animate(25000, style({right: '100%'}))
    ])
  ])
]
})
export class HeaderComponent implements OnInit {
  @Input() headerData:PoweringAIInnovation;

  country_code :any
  @Select(CountryCodeState.currentCountryCode)CountryCodeData$!: Observable<CountryCodeModel>;
  countrycodeSubscription!: Subscription;
  CountrycodeSection:CountryCodeModel
  state = 0;

  constructor(private store:Store,private router:Router,private homeService:HomeService) {
    
   }
  homeSectionData: HomeSectionModel;
  ngOnInit(): void {

    // this.countrycodeSubscription = this.CountryCodeData$.pipe(take(1))
    // .subscribe((state) => {
    //   this.CountrycodeSection = {
    //     ...state
    //   }
    // });
    
    // if(this.CountrycodeSection.country_code){
    //   this.country_code = this.CountrycodeSection.country_code.toLowerCase();
    // }else{
    //   this.homeService.getCountryCode().subscribe(result => {
    //     let payload = {
    //         ...result
    //     }
    //     this.country_code = payload.country_code.toLowerCase( );
    //   }),
    //   err => {

    //   }
    // }
   
    /*this.homeSectionData = this.store.selectSnapshot(HomeState.currentHomeSectionData);
    //if(this.headerData == undefined) {
      if (this.homeSectionData) {
       
       if (this.homeSectionData.powering_ai_innovation) {
        this.headerData = this.homeSectionData.powering_ai_innovation
       }else{*/
        this.homeService.getSolutionDomian().subscribe(result => {
          
          console.log(result)
          this.headerData = result;
        }),
        err => {
  
        }
      /* }
      }else{
        let data = environment.production == true ? HomeDefaultDataProd.powering_ai_innovation:HomeDefaultData.powering_ai_innovation
        this.headerData = {
          ...data
        }
        console.log('in else loop')
      } */
  }

  navigateToSolutions(currentItem:SolutionDomain){
    // this.router.navigateByUrl('solutions?tab='+currentItem.name)
   // console.log("currentItem",currentItem)
    this.router.navigate([`solutions`, { queryParams: {tab: currentItem.name}}]);
  }
  scrollDone() {
    this.state++;
  }

}
