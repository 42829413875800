import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { from } from 'rxjs';
import { TrainingEventComponent } from './training/training-event/training-event.component';
import { PrivacypoliciesComponent } from './policies/privacypolicies/privacypolicies.component';
import { TermsandconditionsComponent} from './policies/termsandconditions/termsandconditions.component';
import {ThankYouComponent} from './thankYou/thank-you/thank-you.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home',
    loadChildren: () => import('./common/home/home.module').then(m => m.HomeModule)
  },
  { path: 'platform/unifyai',
    loadChildren: () => import('./common/unifyai-home/unifyai-home.module').then(m => m.UnifyaiHomeModule)
  },
  { path: 'solutions',
    loadChildren: () => import('./solution/solution/solution.module').then(m => m.SolutionModule)
  },
  { path: 'solutions?:id',
    loadChildren: () => import('./solution/solution/solution.module').then(m => m.SolutionModule)
  },
  { path: 'blog', loadChildren: () => import('./common/blog/blog.module').then(m => m.BlogModule) },
  { path: 'blog/:id', loadChildren: () => import('./common/blog/blog-details/blog-details.module').then(m => m.BlogDetailsModule) },
  { path: 'aboutus', loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusModule) },
  { path: 'career', loadChildren: () => import('./career/career.module').then(m => m.CareerModule) },
  { path: 'careers/:id', loadChildren: () => import('./career/create-career/create-career.module').then(m => m.CreateCareerModule) },
  { path: 'contactUs', loadChildren: () => import('./contact/contact-us/contact-us.module').then(m => m.ContactUsModule) },
 
  { path: 'privacypolicies', component: PrivacypoliciesComponent},
  { path: 'termsandconditions', component: TermsandconditionsComponent},
  { path: 'thankyou', component:ThankYouComponent},
  { path: 'training', loadChildren: () => import('./training/training.module').then(m => m.TrainingModule) },
  { path: 'blog-details', loadChildren: () => import('./common/blog/blog-details/blog-details.module').then(m => m.BlogDetailsModule) },
  { path: 'production', loadChildren: () => import('./common/production-pilot/production-pilot.module').then(m => m.ProductionPilotModule) },
 
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { anchorScrolling: 'enabled' , useHash:true,scrollPositionRestoration: 'enabled'}),
     IvyCarouselModule],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppRoutingModule { }
