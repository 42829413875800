<section class="pageBanner">
    <div class="container">
        <h2 class="pageTitl mt-5">Privacy Policies</h2>
    </div>
</section>
<section class="pageLayout">
    
    <section class="contactUsContSec">
        <div class="container">

            <div class="aboutus-disc privacy-policy mt-5">

                <p>This Privacy Policy (“Privacy Policy”) applies to the corporate website accessible at&nbsp;<a
                        href="https://www.datasciencewizards.ai/">https://www.datasciencewizards.ai/</a> (“Website”) and
                    the cloud-based technology learning platform also accessible through that website, the video players
                    used to view Data Science Wizards courses, and the mobile applications (“Platform”, and together
                    with the Website, collectively, the “Site”), all owned and operated by Data Science Wizards&nbsp;
                    Pvt Ltd company, (“Data Science Wizards”, “we”, or “us”). This Privacy Policy describes how Data
                    Science Wizards collects and uses personal information collected through the Website and the
                    Platform. It also describes the choices available to you regarding the use of, your access to, and
                    how to update and correct your personal information.&nbsp;</p>
                <p>Please read the following carefully to understand our views and practices regarding your personal
                    information and how we will treat it. By engaging with the Website and the Platform you acknowledge
                    you have read and understood this Privacy Policy. Data protection laws require companies to describe
                    their role and responsibility when handling personal information.&nbsp;</p>
                <p>This Privacy Policy sets out:</p>
                <ul>
                    <li>The information we collect about you</li>
                    <li>How we use the information we collect</li>
                    <li>Who we give your information to</li>
                    <li>Control of your information</li>
                    <li>How we protect your information</li>
                    <li>Links to other websites</li>
                    <li>Public forums</li>
                    <li>Payment processing</li>
                    <li>Information about enforcement and dispute resolution</li>
                    <li>Changes to this Privacy Policy</li>
                    <li>Contact us</li>
                    <li>Use of Cookies</li>
                    <li>When you engage with the Platform because your employer has signed up to a Business plan or
                        Corporate plan (as defined in our Terms of Use at <a
                            href="https://www.datasciencewizards.ai/">https://www.datasciencewizards.ai/</a>), then; your
                        employer is the&nbsp;<em>data controller</em>with respect to the user information it provides to
                        us. This information typically includes first name, last name, contact details and business
                        email address.</li>
                    <li>When you sign up as a user of the Platform via an Individual Plan (as defined in the&nbsp;Terms
                        of Use), Data Science Wizards is the&nbsp;<em>data controller</em>for all personal information
                        provided by that user and collected by the Platform as the user accesses and uses it.</li>
                </ul>
                <p>The information we collect about you</p>
                <p>We value your trust. In order to honour that trust, Data Science Wizards adheres to ethical standards
                    in gathering, using, and safeguarding any information you provide.</p>
                <p><strong>We collect and process the following personal information from you:</strong></p>
                <p><strong>Information you give to us:</strong></p>
                <p>This is information about you that you give us directly when you interact with us. You may give it to
                    us by filling out a form on our Site, corresponding with us by phone, e-mail, or at an in-person
                    event. It includes information necessary to register for a subscription, pay for a subscription, or
                    place an order for other services we provide. It also includes information shared when you
                    participate in a discussion board, share on social media via our Site, enter a competition or
                    promotion, submit a query, report feedback about or a problem within our Site, or any of the other
                    Interactive Services (defined in the&nbsp;Terms of Use). When you create an account for our
                    Platform, we require you provide first name, last name, contact details and email address. When you
                    purchase a subscription or courses, we will need payment and billing information, often including an
                    address, payment details and credit card information. Additionally, you can provide additional
                    personal information to complete a profile within our system.&nbsp;</p>
                <p><strong>Information we collect about you from your use of our Site:</strong></p>
                <p>We will automatically collect information from you each time you visit our Site. This includes
                    technical information, information about your visit, and information about your activity on our Site
                    such as courses searched and viewed, page response times, download errors, length of visit to
                    certain pages, page interaction information (such as scrolling, clicks, and mouseovers), methods to
                    browse to and away from a page, and methods used to contact our sales and support teams. Technical
                    information may also include the Internet protocol address used to connect your computer to the
                    Internet, your login information, browser type and version, time zone setting, browser plug-in types
                    and versions, operating systems, and device platform.&nbsp;</p>
                <p><strong>Information we receive from other sources:</strong></p>
                <p>This is information we receive about you from third parties that we work closely with to provide,
                    promote, and improve our services. These third parties include business partners, vendors who assist
                    in technical and payment services, advertising networks, analytics providers, and search information
                    providers.</p>
                <p>How we use the information we collect</p>
                <p><strong>Information you give to us:</strong></p>
                <p>Data Science Wizards uses the personal information you provide in a manner that is consistent with
                    this Privacy Policy. We use your personal information to provide our services on our Site, including
                    services arising from any obligations set forth in a contract between you and us. These services may
                    include providing access to our course library, administration of your account, billing, and
                    notifying you of changes to our service or your account. This data will also be used to allow you to
                    participate in the interactive features of our Site or to provide you with information you may have
                    requested from us, including whitepapers, access to webinars, or the like.</p>
                <p>We also use your personal information together with other personally non-identifiable information to
                    help us better understand our users, to personalize and improve your experience with our Site, and
                    to improve the content and functionality of our Site. This may include providing information about
                    our goods, products, training material and services that we feel may interest you and enhance your
                    interaction with our Site. We will communicate with you about these goods, products, training and
                    services via email, direct mail, telephone, or on our Site. These communications may include
                    newsletters, promotional emails, product updates, or market research requests. We will use your
                    information for this purpose only if you have given your consent to receive marketing material from
                    us at the point we collected your information, where required by law, or otherwise in our legitimate
                    interests, provided these interests do not override your right to object to such communications.</p>
                <p><strong>Information we collect about you from your use of our Site:</strong></p>
                <p>We will use this information in our legitimate interests (where we have considered these are not
                    overridden by your rights), to administer our Site, and for internal operations, including
                    troubleshooting, data analysis, testing, research, and statistical survey purposes. We will also use
                    this information to keep our Site safe and secure, for measuring the effectiveness of how we present
                    content and how we market and advertise. We use Internet protocol addresses and non-personally
                    identifiable information in our log files to analyse trends, to administer our Site, to track users’
                    movements in and around our Site, and to gather demographic information about our user base as a
                    whole. We also utilize artificial intelligence, machine learning technologies and analytics to
                    understand user behaviours and to provide user-specific recommendations and other personalization of
                    our Site experience.&nbsp;</p>
                <p><strong>Information we receive from other sources:</strong></p>
                <p>We will combine this information with information you give to us and information we collect about you
                    in our legitimate interests (where we have considered that these are not overridden by your rights).
                    We will use this information and the combined information for the purposes set out above (depending
                    on the types of information we receive).</p>
                <p>&nbsp;</p>
                <p>Who we give your information to</p>
                <p>We consider your personal information to be a vital part of our relationship with you and do not sell
                    your personal information to third parties. There are, however, certain circumstances in which we
                    may share your personal information with certain third parties, as follows:</p>
                <p><strong>Agents, Consultants and Related Third Parties:&nbsp;</strong></p>
                <p>We sometimes hire other companies to perform certain business-related functions, such as sending
                    email on our behalf, payment processing, or conducting market research. When we employ another
                    company to perform a function of this nature, we only provide them with the information that they
                    need to perform their specific function. These companies are not permitted to use any personal
                    information that we share with them for any other purpose aside from providing services to us.</p>
                <p>We also share information with third parties about customer engagement on our platform. For example,
                    when you view a course about a technology vendor, we share information such as the number of course
                    minutes viewed, hands-on course engagement, and other related data with that technology vendor. We
                    share this information and receive feedback from those third parties in order to improve the content
                    available on our platform and better collaborate with our partners to jointly help companies and
                    individuals address their skill development needs. Unless we specifically ask for and you grant
                    consent, the data we provide to third parties does not include personal information.</p>
                <p><strong>Business Plans and Company Partnership Subscriptions:&nbsp;</strong></p>
                <p>We partner with certain third parties to provide our services to their employees or their community
                    members. As part of such partnerships, a third party may pay for the subscription for its employees
                    or community members or otherwise contract with Data Science Wizards to make your subscription
                    available to you, and Data Science Wizards may share information about your use of our services with
                    the third party. If your subscription was provided by your employer or community sponsor (including
                    Company Partnerships as defined in the&nbsp;Terms of Use), we may share information you provide, or
                    information we may otherwise collect through your registration and use of our services, with such
                    third party. This information may include course viewing history, course viewing time, course or
                    assessment completion, course quality ratings and feedback, certifications, performance-related
                    metrics, and other personal information.</p>
                <p><strong>Business Transfers:&nbsp;</strong></p>
                <p>As we develop our business, we might sell or buy businesses or assets. Your personal information may
                    be transferred to a buyer or other successor in the event of a corporate sale, merger,
                    reorganization, dissolution, or similar event in which personal information held by us about our
                    Site users is among the assets transferred.</p>
                <p><strong>Governing Law and Dispute Resolution</strong></p>
                <p>This Policy shall be governed by and construed in accordance with the laws of the Republic of India.
                    Subject to arbitration, the courts at Mumbai shall have exclusive jurisdiction in relation to any
                    disputes arising out of or in connection with this Policy.</p>
                <p>If any dispute arises between the Company and You in connection with or arising out of the validity,
                    interpretation, implementation or alleged breach of any provision of the Policy, such dispute shall
                    be referred to arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996 for
                    the time being in force. Arbitration shall be conducted by one (1) arbitrator mutually appointed by
                    the Company and You. The seat of arbitration shall be Mumbai, Maharashtra. The language of the
                    arbitration proceedings and of all written decisions and correspondence relating to the arbitration
                    shall be English.</p>
                <p><strong>Legal Requirements:&nbsp;</strong></p>
                <p>In certain situations, Data Science Wizards may be required to disclose personal information in
                    response to lawful requests by public authorities, including meeting national security or law
                    enforcement requirements. We reserve the right to disclose your personal information as required by
                    law and when we believe that disclosure is necessary to protect our rights or to comply with a
                    judicial proceeding, court order, or similar legal process served on us or our Site.</p>
                <p>We will take reasonable steps to ensure that we only collect that personal information that is
                    relevant for the purposes for which it is to be used. Furthermore, we will not process your personal
                    information in a way that is incompatible with these purposes.</p>

                <p> <strong>Control of your information</strong></p>
                <p>If you would like to discontinue receiving communications from us, you may update your email
                    preferences by using the “Unsubscribe” link found in emails we send to you or by contacting us
                    at&nbsp;datasciencewizards.ai. Please note that we may not include the opt-out information in
                    e-mails that are transactional in nature and are not marketing communications (i.e., e-mails
                    concerning your order, the website’s Terms of Use, etc.). You will not have the ability to opt-out
                    of receiving transactional emails. If you have questions or concerns regarding a transactional
                    please contact us.</p>
                <p><strong>How we protect your information</strong></p>
                <p>The security of your personal information is important to us. When you enter sensitive information
                    (such as a credit card number or payment details) on our checkout page, your information is sent
                    over an authenticated and encrypted connection using Transport Layer Security (TLS) through a&nbsp;
                    third party payment gateway.</p>
                <p>We store your personal information only on servers with limited access that are located in controlled
                    facilities and use a variety of technologies and procedures intended to protect your personal
                    information from loss, misuse, unauthorized access, disclosure, alteration and destruction.
                    Nonetheless, no communication via the Internet can ever be 100% secure, and no security measures can
                    ever be assured to be effective. Accordingly, you are advised to use caution and discretion when
                    determining what personal information to disclose to us.</p>
                <p>If you have any questions about security on our Site, contact us as follows:</p>
                <p>Email:&nbsp;<a href="mailto:contact@datasciencewizards.ai">contact@datasciencewizards.ai</a></p>
                <p><strong>Links to other websites</strong> </p>
                <p>This Privacy Policy applies only to our Site. The Site may contain links to other web sites not
                    operated or controlled by us. The policies and procedures described here do not apply to web sites
                    or other services that Data Science Wizards does not operate or control. These links from our Site
                    do not imply that we endorse or have reviewed those web sites or other services. We suggest
                    contacting those services directly for information on their privacy policies.</p>
                <p><strong>Public forums</strong> </p>
                <p>The Site may, from time to time, make chat rooms, message boards, news groups, or other public forums
                    available to its users, whether by way of our own services or by way of the services or features of
                    a third party. Any information that is disclosed in these areas becomes public information and you
                    should exercise caution when using these areas and avoid posting any personal or sensitive
                    information. If a separate login is required to use these areas, please be aware that you may need
                    to also log out separately.</p>
                <p><strong>Payment processing</strong> </p>
                <p>When you purchase a subscription directly from Data Science Wizards, payment details you provide in
                    the designated checkout page on our Site will be encrypted using the Transport Layer Security (TLS)
                    protocol before they are submitted to us over the internet. Payments made on our Site are made
                    through one of our payment gateway providers like PayPal, banks, payment vault (“<strong>Payment
                        Processors</strong>”), which may be changed from time to time. You will be providing credit or
                    debit card information directly to the Payment Processor which operates a secure server to process
                    payment details, encrypting your credit/debit card information and authorizing payment. Information
                    which you supply to the Payment Processor is not within our control and is subject to the Payment
                    Processor’s own privacy policy and terms and conditions.</p>
                <p><strong>Information about enforcement and dispute resolution</strong> </p>
                <p>We will conduct compliance audits of our relevant privacy practices to verify compliance with this
                    Privacy Policy. Any Data Science Wizards employee that we determine has acted in violation of this
                    Privacy Policy will be subject to disciplinary action up to and including termination of employment.
                </p>
                <p>Any questions or concerns regarding our use or disclosure of personal information should be addressed
                    to Customer Support at&nbsp;<a
                        href="mailto:contact@datasciencewizards.ai">contact@datasciencewizards.ai</a>. We will investigate and
                    attempt to resolve any complaints and disputes regarding the use and disclosure of personal
                    information in accordance with the provisions of this Privacy Policy.</p>
                <p><strong>Changes to this Privacy Policy</strong> </p>
                <p>As our Site evolves, this Privacy Policy will need to evolve as well to cover new situations. To
                    ensure that you are kept informed of changes, check this Privacy Policy periodically as we may or
                    may not update the date at the top of the document any time we make a change. If we make material
                    changes to this Privacy Policy, we will post the revised policy on this page and may send a notice
                    via email or our Site to all registered users of the Platform. Your continued use of our Site after
                    any such changes constitutes your acceptance of the new Privacy Policy. If you do not agree to abide
                    by these or any future version of the Privacy Policy, you do not have permission to use or access
                    (or continue to use or access) our Site.</p>
                <p><strong>Contact us</strong> </p>
                <p>Questions or comments regarding this Privacy Policy should be submitted to Data Science Wizards by
                    e-mail as follows:</p>
                <p>Email:&nbsp;<a href="mailto:contact@datasciencewizards.ai">contact@datasciencewizards.ai</a></p>
                <p><strong>Use of Cookies</strong> </p>
                <p>Data Science Wizards and its affiliates use cookies or similar technologies to collect and store
                    certain information. These typically involve pieces of information or code that a website transfers
                    to or accesses from your computer hard drive or mobile device to store and sometimes track
                    information about you. Cookies allow us to create a unique device ID, enable you to be remembered
                    when using that computer or device to interact with websites and online services, and can be used to
                    manage a range of features and content, including storing searches and presenting personalized
                    content.</p>
                <p>The Site uses cookies to distinguish you from other users of our Site. This helps us to provide you
                    with a good and personalized user experience when you interact with our Site and also allows us to
                    improve our Site.&nbsp;</p>
                <p>Most web browsers automatically accept cookies, but if you prefer, you can change your browser to
                    prevent that. We also give you information about how to disable cookies. However,&nbsp; you may not
                    be able to take full advantage of our Site if you do so.&nbsp;</p>
                <p>A number of cookies we use last only while you are on our Site and expire when you close your browser
                    or exit our Site. Some cookies are used to remember you when you return to our Site and will last
                    for longer. We use these cookies on the basis that they are necessary for the performance of a
                    contract with you, or because using them is in our legitimate interests (where we have considered
                    that these are not overridden by your rights), and, in some cases, where required by law, where you
                    have consented to their use.</p>
                <p><strong>We use the following types of cookies:</strong> </p>
                <ul>
                    <li><strong>Strictly necessary cookies.</strong>These are cookies that are required for the
                        operation of our website and under our terms with you. They include, for example, cookies that
                        enable you to log into secure areas of our website, use a shopping cart or make use of e-billing
                        services.&nbsp;</li>
                    <li><strong>Analytical/performance cookies.&nbsp;</strong>They allow us to recognize and count the
                        number of visitors and to see how visitors move around our website when they are using it. This
                        helps us for our legitimate interests of improving the way our website works, for example, by
                        ensuring that users are finding what they are looking for easily.&nbsp;</li>
                    <li><strong>Functionality cookies.&nbsp;</strong>These are used to recognize you when you return to
                        our website. These enable us, subject to your choices and preferences, to personalize our
                        content, greet you by name and remember your preferences (for example, your choice of language
                        or region).</li>
                    <li><strong>Targeting cookies.&nbsp;</strong>These cookies record your visit to our website, the
                        pages you have visited and the links you have followed. We will use this information subject to
                        your choices and preferences to make our Site and the advertising displayed on it more relevant
                        to your interests. We may also share this information with third parties for this purpose.</li>
                </ul>
                <p>We may also work with marketing and advertising networks that gather information about the content on
                    our Site you visit and on information on other websites and services you visit. This may result in
                    you seeing advertisements on our Site or our advertisements when you visit other websites and
                    services of third parties.</p>
                <p><strong>Disabling cookies</strong></p>
                <p>The effect of disabling cookies depends on which cookies you disable but, in general, our Site may
                    not operate properly if all cookies are switched off. If you only disable third party cookies, you
                    will not be prevented from making purchases on our Site. If you disable all cookies, you will be
                    unable to complete a purchase on our Site.</p>
                <p>If you want to disable cookies on our Site, you need to change your website browser settings to
                    reject cookies. How you can do this will depend on the browser you use. Further details on how to
                    disable cookies for the most popular browsers are set out below:</p>
                <p><strong>For Microsoft Internet Explorer:</strong></p>
                <ol>
                    <li>Choose the menu “tools” then “Internet Options”</li>
                    <li>Click on the “privacy” tab</li>
                    <li>Select the setting the appropriate setting</li>
                </ol>
                <p><strong>For Google Chrome:</strong></p>
                <ol>
                    <li>Choose Settings&gt; Advanced</li>
                    <li>Under “Privacy and security,” click “Content settings”.</li>
                    <li>Click “Cookies”</li>
                </ol>
                <p><strong>For Safari:</strong></p>
                <ol>
                    <li>Choose Preferences &gt; Privacy</li>
                    <li>Click on “Remove all Website Data”</li>
                </ol>
                <p><strong>For Mozilla Firefox:</strong></p>
                <ol>
                    <li>Choose the menu “tools” then “Options”</li>
                    <li>Click on the icon “privacy”</li>
                    <li>Find the menu “cookie” and select the relevant options</li>
                </ol>
                <p><strong>For Opera 6.0 and further:</strong></p>
                <ol>
                    <li>Choose the menu Files”&gt; “Preferences”</li>
                    <li>Privacy</li>
                </ol>
                <p>Where you have not set your permissions, we may also separately prompt you regarding our use of
                    cookies on our Site.</p>
                <p>Except for essential cookies, all cookies used on our Site will expire after two years.</p>
                <p>&nbsp;</p>

            </div>
        </div>
    </section>
</section>