import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'scrollable-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() tabs: any[] =[];
  selectedIndex = 0;
  abc: string | undefined;
  leftTabIdx = 0;
  atStart = true;
  atEnd = false
  @Output() emitSelectedTab = new EventEmitter()
  constructor() {

   }

  ngOnInit() {
    this.emitSelectedTab.emit(this.tabs[0])
    this.abc = `translateX(0px)`
    console.log(this.tabs)
  }

  selectTab(index: number) {
    this.selectedIndex = index
    this.emitSelectedTab.emit(this.tabs[index])
    this.scrollTab(index-this.leftTabIdx-1)
  }

  scrollTab(x: number) {
    if (this.atStart && x < 0 || this.atEnd && x > 0) {
      return
    }
    this.leftTabIdx = this.leftTabIdx + x
    this.abc = `translateX(${(this.leftTabIdx) * -10}px)`
    this.atStart = this.leftTabIdx === 0
    this.atEnd = this.leftTabIdx === this.tabs.length -1
  }

}
