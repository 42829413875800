<section class="tabs-container">
	<span class="change-btn prev" (click)="scrollTab(-1)" [class.hide]="atStart">
&lsaquo;</span>

	<ul class="tabs" [ngStyle]="{'transform': abc }">

		<ng-container *ngFor="let tab of tabs; let i=index">
			<li class="tab" [class.active]="selectedIndex===i" (click)="selectTab(i)">{{tab}}</li>
		</ng-container>
	</ul>
  <span class="change-btn next" [class.hide]="atEnd" (click)="scrollTab(1)">&rsaquo;</span>
</section>